@import "./ant-overwrite.scss";
@import "./tables.scss";
@import "./settings.scss";

body {
  background-color: #E5E9E9;
}

.app {
  text-align: center;
  margin-right: 48px;
  padding-top: 48px;

  &_drawer-open {
    margin-left: 328px;
  }
}

.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  transition: transform ease 0.3s;
  padding-bottom: 50px;
}

.content {
  position: relative;
  width: 100%;
}

.content__wrap {
  margin-top: 24px;
  text-align: left;
  min-width: 100%;
  width: 100%;
  border-radius: 6px;
  background-color: #FFFFFF;
  position: relative;
  &_wheader {
    padding-top: 54px;
  }
}

.loader {
  position: absolute;
  left: 0;
  right: 0;
  background-color: rgba(255,255,255,.75);
  z-index: 1;
  top: 0;
  bottom: 0;
  display: flex;
  padding-top: 150px;
  justify-content: center;

  .content & {
    top: 24px;
  }
}