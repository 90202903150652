.tab-content {
  padding: 24px
}

.action-btns {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 10px;
  right: 16px;

  .ant-btn ~ .ant-btn { margin-left: 16px; }
}