.table {

  &.table_clickable .ant-table-row { cursor: pointer; }

  &.table_labels {}

  .ant-pagination-options .ant-select-dropdown {
    right: -16px !important;
    left: auto !important;
  }
}

.table-total {
  position: absolute;
  bottom: 24px;
  left: 1rem;
  color: #000;
  font-size: 14px;

  &_inline {
    position: static;
    padding: 1rem;
    display: block;
  }
}

.table__action-link {
  text-decoration: none;

  &_warning {
    color: rgba(red, 0.7);
  }
}