.btn {
  color: #03292A;
  font-size: 14px;
  padding-left: 16px;
  padding-right: 16px;

  &.btn-primary {
    min-width: 145px;
    background-color: #03292A;
    border: 1px solid #03292A;
    color: #fff;

    &:hover {
      color: #fff;
    }
  }

  &.btn-default {
    min-width: 122px;
    border: 1px solid #03292A;
    background-color: #FFFFFF;

    &:hover {
      color: #03292A;
    }
  }
}
