.ant-list .ant-list-item {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 46px;
  background-color: rgba(0, 0, 0, 0.02);
}

.ant-list .org-item.ant-list-item {
  min-height: 54px;
  background-color: #fff;
  padding-left: 24px;
}

.ant-list.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 0;
}

.ant-list.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0;
}

.ant-table-pagination.ant-pagination {
  margin-right: 16px;
}

.ant-table-title {
  padding-left: 16px;
  font-size: 14px;
}

.ant-popover-buttons button {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-select-selection-placeholder {
  opacity: .8;
}