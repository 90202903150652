.header {
  min-width: 100%;
  width: 100%;

  :global(.ant-page-header) {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 32px;
    //min-height: 208px;
    min-height: 96px;
    border-radius: 6px;
    background-color: #FFFFFF;
  }

  :global(.ant-page-header-heading-title) {
    color: #273131;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 33px;
  }

  :global(.ant-page-header-heading-extra) {
    display: flex;
  }

  :global(.ant-page-header-heading-extra .ant-btn) {
    margin-left: 0;
    
    &:first-child { margin-right: 16px; }
  }
}

.stat-row {
  display: flex;
  margin-top: 20px;

  span {
    flex-grow: 1;
  }

  :global(.ant-statistic) {
    text-align: left;
    height: 80px;
    padding: 12px 16px;
    margin-right: 16px;

    &:hover {
      cursor: pointer;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }

  :global(.ant-statistic-title) {
    height: 24px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }

  :global(.ant-statistic-content) {
    color: #273131;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 29px;
  }

  :global(.ant-statistic-content-suffix) {
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px;
  }
}

.info {
  text-align: left;
  h4 {

  }
  div {}
}

.only-btn { margin-right: 0 !important; }