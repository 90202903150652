.bulk-orgs-table {
  .ant-table-content .ant-table-tbody .ant-table-cell {
    vertical-align: top;
    br { display: block }
    //br + br { display: none }
  }
}

.bulk-org-desc {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
  white-space: pre-wrap;

  &_visible {
    display: block
  }
}