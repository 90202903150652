.tabs {
  margin-top: 24px;
  text-align: left;
  min-width: 100%;
  width: 100%;
  border-radius: 6px;
  background-color: #FFFFFF;
}

.ant-tabs-nav-wrap {
  padding-left: 24px;
}

.ant-tabs-nav .ant-tabs-ink-bar {
  background-color: #03292A;
}

.ant-tabs .ant-tabs-bar {
  margin-bottom: 0;
}

.ant-tabs .ant-tabs-nav .ant-tabs-tab {
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  letter-spacing: 0;
  padding-left: 0;
  padding-right: 0;
  min-height: 54px;
  padding-top: 16px;
}

.ant-tabs .ant-tabs-nav .ant-tabs-tab:hover {
  color: #335EB0;
}

.ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active {
  color: #03292A;
  font-weight: 500;
  letter-spacing: 0;
}