.org {
  display: flex;
  align-items: center;
}

.group {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 14px;
  height: 46px;
}

.group-number {
  //margin-left: 24px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

.org-title {
  min-width: 264px;
  color: #335EB0;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
}

.group-score {
  height: 32px;
  width: 40px;
  border-radius: 6px;
  background-color: #D6DDDD;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #03292A;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.groups-list {
  .ant-list-item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-right: 16px;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}

.group-desc {
  margin: 0;
  padding: 0;
  text-align: left;
}

.group-action {
  margin: 0;
  padding: 0;
  text-align: left;
  white-space: nowrap;
}

.group-org-list {
  background-color: #fff;
  min-width: 100%;
  margin: -16px;
  padding: 10px;

  .ant-table { margin: 0 !important; }
}

.group-org-name {
  padding-left: 130px;

  &:last-child {
    padding-bottom: 20px
  }

  .org-title {
    margin-left: 0;
    color: #335EB0;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
  }
}

/*.group-caret {
  height: 12px;
  width: 12px;
  font-size: 12px;
  color: rgba(#000, .65);
  margin-right: 14px;

  &_down {
    transform: rotate(90deg);
  }
}*/