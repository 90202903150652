.user-menu {
  margin-top: auto;

  &.user-menu_last {
    margin-top: 0;
  }
}

.user-menu__dropdown {
  padding: 0 12px 0 8px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  height: 40px;
  min-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  background-color: #FFFFFF;
  color: #2C3838;

  &:global(.ant-dropdown-open) {
    border-color: #335EB0;

    .user-menu__arrow {
      transform: rotate(180deg);
    }
  }
}

.user-menu__name {
  color: #2C3838;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
  white-space: nowrap;
}

.user-menu__initials {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: #03292A;
  color: #FFFFFF;
  font-size: 10.5px;
  font-weight: 500;
  margin-right: 8px;
  border-radius: 44px;
}

.user-menu__arrow {
  margin-left: auto;
}

.user-menu__link {
  &:global(.ant-dropdown-menu-item) {
    color: #03292A;
    font-size: 14px;
    font-weight: 500;

    span {
      display: block
    }
  }

  &.user-menu__link_alert {
    color: #C23333;
  }
}