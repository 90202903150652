.app-drawer {
  :global(.ant-drawer-body) {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  :global(.ant-drawer-content-wrapper) {
    box-shadow: none !important;
  }
}

.logo {
  height: 30px;
  min-height: 30px;
  width: 162px;
  margin: 0 auto;
  display: block;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-image: url('./logo.svg');
}

.account-selector {
  &:global(.ant-select) {
    margin-top: 24px;
    width: 248px;
  }

  :global(.ant-select-selection) {
    height: 40px;
    width: 248px;
    border-color: rgba(0, 0, 0, 0.15);
  }

  :global(.ant-select-selection__rendered) {
    line-height: 40px;
    margin-left: 12px;
  }

  :global(.ant-select-selection-selected-value) {
    color: #273131;
    font-size: 14px;
    font-weight: 500;
  }
}

.drawer-menu {
  &:global(.ant-menu) {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 24px;
    border-right: none;
  }

  &:global(.ant-menu-vertical .ant-menu-item) {
    font-size: 14px;
    font-weight: 500;
  }

  &:global(.ant-menu-vertical .ant-menu-item:hover) {
    color: #335EB0;
  }

  :global(.ant-menu-vertical .ant-menu-item-selected) {
    background-color: #EFF3F9;
    color: #335EB0;
  }

  &_bottom:global(.ant-menu) {
    margin-top: auto;
    margin-bottom: 16px
  }
}


